 @tailwind base;
 @tailwind components;
 @tailwind utilities;
 /*  */
 
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Orbitron&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Josefin+Slab:wght@600&family=Orbitron&display=swap');
 /*  */
 
 .Inter-text {
     font-family: 'Inter';
 }
 
 .Jospin-text {
     font-family: 'Josefin Slab';
 }
 
 .home_main {
     background-image: url(./assets/Main.jpg);
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
 }
 
 .logo {
     animation-duration: 1s;
     animation: ani 6s ease infinite;
 }
 
 @keyframes ani {
     0% {
         transform: scale(1);
     }
     50% {
         transform: scale(1.1);
     }
     100% {
         transform: scale(1);
     }
 }
 
 .learnMoreBtn {
     animation-duration: 1s;
     animation: btnAnimation 2s ease infinite;
 }
 
 @keyframes btnAnimation {
     0% {
         transform: scale(1);
     }
     50% {
         transform: scale(1.1);
         /* box-shadow: inset 5px 5px 10px rgb(121, 121, 121); */
     }
     100% {
         transform: scale(1);
     }
 }
 
 .story_bg {
     background-image: url(./assets/story_bg.JPG);
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
 }
 
 .paraFont1 {
     font-family: 'Playfair Display';
 }
 
 .paraFont2 {
     font-family: 'Playpen Sans';
 }
 
 .paraFont3 {
     font-family: 'Caveat';
 }
 
 .paraFont4 {
     font-family: 'Lilita One';
 }
 
 .paraFont5 {
     font-family: 'Roboto';
 }
 
 .about_bg {
     background-image: url(./assets/aboutBG.JPG);
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
 }